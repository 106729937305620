/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

const Offer15 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "ig-oczy-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, quality: 100, formats: [WEBP])
        }
      }
      two: file(relativePath: { eq: "ig-oczy-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.sunekos}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Mezoterapia aminokwasowa
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="sunekos ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Wskazania</Subtitle>
            <Paragraph>
              Zabiegi mezoterapii aminokwasowej mają na celu dogłębne nawilżenie
              i rozświetlenie skóry, zniwelowanie cieni pod oczami, wygładzenie
              zmarszczek i modelowanie konturów twarzy.
            </Paragraph>
            <Paragraph>
              Ze względu na sposób działania preparat możemy podać na:szyję,
              dekolt, dłonie, ramiona, wewnętrzną stronę ud, obszar pod
              kolanami, powieki (likwiduje cienie)lub na zmarszczki pod oczami.
              Najczęściej stosowany jest jako zabieg na cienie pod oczami. Jest
              alternatywą dla pacjentów, którzy nie kwalifikują się do
              wypełnienia doliny łez kwasem hialuronowym.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu</Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej. Mezoterapię aminokwasową podaje się w formie
              iniekcji w skórę twarzy, szyi, dekoltu, dłoni i innych części
              ciała, które utraciły kolagen i elastynę. Po przygotowaniu
              preparatu (rozpuszczenie aminokwasów w kwasie hialuronowym) i
              dezynfekcji skóry wykonuje się iniekcje mikrodepozytów. Produkt
              można też podawać za pomocą kaniuli.
            </Paragraph>
            <Paragraph>
              Zabieg wykonuje się w miejscu wcześniej znieczulonym przy pomocy
              specjalnego kremu.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty</Subtitle>
            <Paragraph>
              Pierwsze efekty zabiegu widoczne są już po miesiącu. W celu
              kompleksowego odmłodzenia skóry najczęściej wykonuje się serię 4
              zabiegów, w odstępie ok. 10 dni. Zdjęcia ukazują efekt zabiegu u
              konkretnego pacjenta. Efekt zabiegu może się różnić w zależności
              od indywidualnych cech pacjenta, liczby powtórzeń zabiegu,
              stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności
              i doświadczenia osoby przeprowadzającej zabieg.
            </Paragraph>
            <Subtitle>
              Zdjęcia przed po zabiegu mezoterapii aminokwasowej wokół oczu
            </Subtitle>
            <SImageWrapper>
              <Image
                src={images.one.childImageSharp}
                alt="sunekos mezotrapia aminokwasowa wokół oczu biostymulator"
              />
              <Image
                src={images.two.childImageSharp}
                alt="sunekos mezotrapia igłowa oczy biostymulator"
              />
            </SImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena</Subtitle>
            <Paragraph>
              Cena pojedyczego zabiegu to 700 zł. Przy pierwszej wizycie odbierz
              swoją kartę lojalnościową i otrzymaj -30% na czwarty zabieg
              medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer15;

export const Head = () => <SEO tags={TAGS.sunekos} />;
